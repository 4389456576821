<template>
  <category-select :placeholder="placeholder" :value="value" @input="handleInput" :has-error="hasError"></category-select>
</template>

<script>
import inputMixin from '@/modules/forms/components/inputs/inputMixin'
import CategorySelect from '@/components/products/categories/CategorySelect'

export default {
  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },
  mixins: [
    inputMixin
  ],
  components: {
    CategorySelect
  },
  methods: {
    handleInput (evt) {
      this.handleChange(evt)
      this.$emit('input', evt)
    }
  }
}
</script>
